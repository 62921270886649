import PortfolioLayout from "../layout/PortfolioLayout";
import About from "../pages/About/About";
import Main from "../pages/Main/Main";
import Projects from "../pages/Projects/Projects";
import Gym from "../photos/Gym.png";
import Bigus from "../photos/Bigus.png";
import NFT from "../photos/NFT.png";
import ForumFushion from "../photos/ForumFushion.png";
import Marketplace from "../photos/Marketplace.png";
import Linn from "../photos/Linn.png";

export const ABOUT_ROUTE = "/aboutme";
export const MAIN_ROUTE = "/";
export const PROJECTS_ROUTE = "/projects";
export const LINKEDIN_LINK =
  "https://linkedin.com/in/kamiletoleikyte";
export const GITHUB_LINK = "https://github.com/MonnoDev";
export const EMAIL_LINK = 'mailto:toleikyte.kamile@gmail.com'

export const porfolioRoutes = {
  Layout: PortfolioLayout,
  routes: [
    {
      path: MAIN_ROUTE,
      Component: Main,
    },
    {
      path: ABOUT_ROUTE,
      Component: About,
    },
    {
      path: PROJECTS_ROUTE,
      Component: Projects,
    },
  ],
};

export const mainPageRoutes = [
  { route: ABOUT_ROUTE, title: "About" },
  { route: PROJECTS_ROUTE, title: "Projects" },
];

export const socials = [
  { route: LINKEDIN_LINK, title: "LI" },
  { route: GITHUB_LINK, title: "GIT" },
  { route: EMAIL_LINK, title: "EMAIL" },
];

export const projects = [
  {
    id: 1,
    image: ForumFushion,
    title: "ForumFushion",
    githubLink: "https://github.com/MonnoDev/ForumFushion",
  },
  {
    id: 2,
    image: Bigus,
    title: "Bigus",
    githubLink: "https://github.com/MonnoDev/Bigus_app",
  },
  {
    id: 3,
    image: Gym,
    title: "Gym_app",
    githubLink: "https://github.com/MonnoDev/Gym_app",
  },
  {
    id: 4,
    image: Marketplace,
    title: "Marketplace",
    githubLink: "https://github.com/MonnoDev/Marketplace",
  },
  {
    id: 5,
    image: NFT,
    title: "NFT_app",
    githubLink: "https://github.com/MonnoDev/NFT",
  },
  {
    id: 6,
    image: Linn,
    title: "Photo_app",
    githubLink: "https://github.com/MonnoDev/Photo-app",
  },
];
