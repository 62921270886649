import React from "react";
import "./Button.css";

const Button = ({ children, url, onClick }) => {
  if (url) {

    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className="custom-button">
        {children}
      </a>
    );
  } else {

    return (
      <button className="custom-button" onClick={onClick}>
        {children}
      </button>
    );
  }
};

export default Button;
