import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RiLinkedinLine, RiGithubLine } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import { GoHome } from "react-icons/go";
import { socials, MAIN_ROUTE } from "../../routes/const";
import "./Navigation.css";

const Navigation = () => {
  const location = useLocation();
  const isHomePage = location.pathname !== MAIN_ROUTE;

  const openSocialLink = (event, socialLink) => {
    event.preventDefault();
    window.open(socialLink, '_blank', 'noopener noreferrer');
  };

  return (
    <nav>
      {isHomePage ? (
        <Link to={MAIN_ROUTE}>
          <GoHome />
        </Link>
      ) : (
        socials.map((social) => (
          <Link to={social.route} key={social.title} onClick={(event) => openSocialLink(event, social.route)}>
            {social.title === "LI" ? (
              <RiLinkedinLine />
            ) : social.title === "GIT" ? (
              <RiGithubLine />
            ) : social.title === "EMAIL" ? 
              <MdOutlineEmail />
            : (
              social.title
            )}
          </Link>
        ))
      )}
    </nav>
  );
};

export default Navigation;



